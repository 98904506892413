<template>
  <div class="d-flex align-items-center">
    <div>
      <feather-icon
        :icon="`${statusOfItem.icon}`"
        :style="{...styles, ...statusOfItem.style}"
      />
      <span v-show="withLabel">
        {{ statusOfItem ? statusOfItem.label : '-' }}
      </span>
    </div>
  </div>
</template>

<script>
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'StateOfAsset',
  props: {
    data: {
      type: Object,
      required: true,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    statusOfItem() {
      return Object.entries(assetStates).find(([_, value]) => value.code === Number(this.data?.status))?.[1]
    },
  },
  setup() {
    return {
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
<style lang="scss"></style>
