import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'

const endpoint = 'warehouse'
const Actions = crudActions(endpoint)
const getFulFillment = (_, { id }) => axios.get(`orders/rental-sale-v2/${id}/fulfillment`)
const sendEmailSignedDocument = (_, { id }) => axios.post(`orders/rental-sale-v2/${id}/send-email-signed-document`)

const orderItem = (callBack, { id, orderEndpoint }) => customActionGet(`${orderEndpoint}/${id}/fulfillment`, null, callBack.commit, 'SET_ORDER_ITEM').catch(err => {
  this.errorNotification(this, err)
})

const dispatchCheckout = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/dispatch-checkout`, body)

const dispatchAttachment = (_, payload) => axios.post(`/customers/${payload.customerId}/contacts/${payload.contactId}/dispatch-attachment`, payload.attachments)

export default {
  ...Actions,
  getFulFillment,
  orderItem,
  dispatchCheckout,
  sendEmailSignedDocument,
  dispatchAttachment,
}
