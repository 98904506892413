<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Dispatch') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Contact') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ getValueFromGivenObjectByKey(orderItem, 'dispatch_contact.fullName') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Phone') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ getValueFromGivenObjectByKey(orderItem, 'dispatch_phone') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Email') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ getValueFromGivenObjectByKey(orderItem, 'dispatch_email') }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'Dispatch',
  components: {
    BRow,
    BCol,
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return { MODULE_NAME, getValueFromGivenObjectByKey }
  },
}
</script>

<style scoped lang="scss">

</style>
