<template>
  <div v-if="exceptionStatusItem">
    <div
      class="d-flex align-items-center mt-1 mb-1"
      :style="{ background: exceptionStatusItem.color, height: '30px' }"
    >
      <feather-icon
        class="ml-1"
        :icon="exceptionStatusItem.icon"
        size="18"
        style="color: #fff"
      />
      <span
        class="w-100 text-white font-weight-bold ml-1"
      >
        {{ exceptionStatusItem.text }}
      </span>
    </div>
  </div>
</template>

<script >

import * as _ from 'lodash'

export default {
  name: 'OrderExceptionStatus',
  props: {
    item: {
      type: Object,
      default: null,
    },
    forFulfillmentOrder: {
      type: String,
      required: false,
      default: null,
    },
    moduleName: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    exceptionStatusItemMerge() {
      return this.item && _.has(this.item, 'exception_statuses') ? _.mergeWith(this.item.exception_statuses, this.exception_statuses, (objValue, srcValue) => {
        if (!_.isArray(objValue)) {
          return _.assign(srcValue, { text: objValue })
        }
      }) : {}
    },
    // exceptionStatusForFulfillment() {
    //   const icon = 'LSignatureIcon'
    //   const text = 'Order is still in hold state (not approved)'
    //   const color = 'orange'
    //
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.isApproved = !this.checkForApprovedRule(this.forFulfillmentOrder, this.item.state)
    //
    //   if (this.isApproved === false) {
    //     return { color, text, icon }
    //   }
    //
    //   return nullweqew
    // },
    exceptionStatusItem() {
      return (this.item && _.has(this.item, 'exception_status'))
        ? this.exceptionStatusItemMerge[this.item.exception_status]
        : null
    },
  },
  // mounted() {
  //   if (this.moduleName) {
  //     this.$store.commit(`${this.$props.moduleName}/SET_IS_ORDER_APPROVED`, this.isApproved)
  //   }
  // },
  methods: {
    // checkForApprovedRule(orderType, state) {
    //   let theResult = false
    //   switch (orderType) {
    //     case 'TO':
    //       if (state !== 7) { theResult = true } break
    //     case 'SM':
    //       if (state !== 6) { theResult = true } break
    //     case 'SO':
    //       if (state !== 6) { theResult = true } break
    //     case 'RO':
    //       if (state !== 6) { theResult = true } break
    //     case 'HO':
    //       if (state !== 6) { theResult = true } break
    //     case 'SR':
    //       if (state !== 6) { theResult = true } break
    //
    //     default: theResult = false
    //   }
    //   return theResult
    // },
  },
  setup() {
    return {
      exception_statuses: {
        1: { color: 'orange', icon: 'LSignatureIcon' },
        2: { color: 'red', icon: '' },
        3: { color: 'red', icon: '' },
        4: { color: 'red', icon: '' },
        5: { color: 'red', icon: '' },
      },
    }
  },
}
</script>

<style scoped lang="scss">

</style>
