<template>
  <div>
    <b-modal
      :id="`report-warning-modal-${orderItem.id}`"
      :ref="`report-warning-modal-${orderItem.id}`"
      centered
      hide-header
      hide-footer
      size="lg"
      body-class="bs-warning-modal__body px-2 py-2"
      modal-class="bs-warning-modal"
    >
      <div class="text-center">
        <h4 class="font-weight-bolder">
          {{ getValueFromGivenObjectByKey(orderItem, 'asset_id', "") }} — {{ getValueFromGivenObjectByKey(orderItem, 'product.name', "") }}
        </h4>
        <div class="row">
          <div class="col-12 text-left mb-1">
            <l-textarea
              v-model="descriptionReport"
              name="reportDescription"
              class="text-left mt-1"
              :field="{
                placeholder: 'Type...',
                label: 'Report Description',
                rules: 'required'
              }"
            />
          </div>
          <div class="col-12 text-left mb-1">
            <l-select
              v-model="dataReport"
              name="attachment_category_id"
              :field="{
                options: { transition: '' },
                store: 'issueTypeList',
                rules: 'required',
                label: 'Issue Type',
              }"
            />
          </div>
          <div class="row pl-sm-2 col-12">
            <div class="col-6 text-left mb-1">
              <span class="font-weight-bolder">
                {{ $t('Asset:') }}
              </span> {{ getValueFromGivenObjectByKey(orderItem, 'asset_id', "") }}
            </div>
            <div
              v-if="orderItem.location"
              class="col-6 text-left mb-1"
            >
              <span class="font-weight-bolder">
                {{ $t('By:') }}
              </span> {{ getValueFromGivenObjectByKey(orderItem, 'location.reference_name', "") }}
            </div>
            <div class="col-6 text-left mb-1">
              <span class="font-weight-bolder">
                {{ $t('Last Known Status:') }}
              </span> {{ orderItem.statuses[getValueFromGivenObjectByKey(orderItem, 'status', "")] }}
            </div>
            <div
              v-if="orderItem.location"
              class="col-6 text-left mb-1"
            >
              <span class="font-weight-bolder">
                {{ $t('On:') }}
              </span> {{ convertTZ(getValueFromGivenObjectByKey(orderItem, 'location.created_at', "")) }}
            </div>
            <div class="col-6 text-left mb-1">
              <span class="font-weight-bolder">
                {{ $t('Order:') }}
              </span> {{ getValueFromGivenObjectByKey(orderItem, 'id', "") }}
            </div>
          </div>
        </div>
      </div>
      <attachments />
      <div class="d-flex justify-content-between">
        <b-button
          variant="outline-primary"
          size="md"
          class="mr-2 flex justify-start"
          @click="hideModal(orderItem.id)"
        > <span>{{ $t('Cancel') }}</span></b-button>

        <b-button
          v-if="isReceiveAsset"
          variant="success"
          size="md"
          :disabled="!descriptionReport"
          @click="reportAsset(orderItem.id)"
        >
          <span>{{ $t('Received') }}</span>
        </b-button>
        <b-button
          v-else
          variant="success"
          size="md"
          :disabled="!descriptionReport"
          @click="reportAsset(orderItem.id)"
        >
          <span>{{ $t('Report as Returned') }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { assetStates } from '@/enum/asset-statuses'
import Attachments
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/components/Attachment.vue'

export default {
  name: 'ReportModal',
  components: { Attachments, BButton },
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      descriptionReport: '',
      dataReport: '',
    }
  },
  computed: {
    isReceiveAsset() {
      return this.orderItem.status.toString() === assetStates.MISSING.code.toString()
    },
  },
  watch: {
    orderItem: {
      handler(val) {
        this.descriptionReport = val.description || ''
      },
      deep: true,
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    showModal(item) {
      return this.$bvModal.show(`report-warning-modal-${item.id}`)
    },
    hideModal(id) {
      this.$bvModal.hide(`report-warning-modal-${id}`)
    },
    reportAsset(id) {
      const data = {
        order_product_id: String(this.orderItem.id),
        attachment_category_id: this.dataReport.id,
      }
      this.$store.dispatch(`warehouse-orders/${this.isReceiveAsset ? 'receiveAsset' : 'reportAsset'}`, this.isReceiveAsset ? data : { ...data, description: this.descriptionReport }).then(success => {
        if (success) {
          this.$emit('reportedAsset', this.orderItem)
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',
              text: `Successfully ${this.isReceiveAsset ? 'received' : 'reported'} asset`,
            },
          })
        }
      })
        .catch(err => {
          this.error = err.response?.data?.message || err.response?.data?.errors
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${this.error}`,
            },
          })
        })
        .finally(() => {
          this.hideModal(id)
        })
    },
  },
}
</script>

<style scoped lang="scss">
#bs-warning-modal .modal-dialog {
  max-width: 500px !important;
}
</style>
