export default {
  warehousePageTitle: 'Fulfillment Orders',
  warehouseOrdersList: {
    orders: null,
    unresolved: [],
  },
  globalVariable: null,
  warehouseDatePicker: {
    isSelectedCurrentWeek: false,
    currentMonthTitle: '',
    selectDayOfWeek: new Date(),
  },
  isLoading: false,
}
