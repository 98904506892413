<template>
  <div>
    <quote-status-bar
      :quote-status-bar="quoteStatusBarItems"
      :item="quoteStatusItem"
      class="mb-1"
    />
    <order-exception-status
      :item="orderItem"
    />
    <b-row
      v-if="hiddeSomeBlocksByOrderType"
      class="mb-1"
    >
      <b-col cols="6">
        <service-order-dispatch
          v-if="orderType === ORDER_TYPES_KV.SM"
          :order-item="orderItem"
        />
        <transfer-dispatch
          v-if="orderType === ORDER_TYPES_KV.TO"
          :order-item="orderItem"
        />
        <rental-sales-dispatch
          v-if="!hiddeSomeBlocksByOrderType"
          :order-item="orderItem"
        />
      </b-col>
      <b-col cols="6">
        <service-order-return
          v-if="orderType === ORDER_TYPES_KV.SM"
          :order-item="orderItem"
        />
        <transfer-return
          v-if="orderType === ORDER_TYPES_KV.TO"
          :order-item="orderItem"
        />
        <rental-sales-return
          v-if="!hiddeSomeBlocksByOrderType"
          :order-item="orderItem"
        />
      </b-col>
    </b-row>
    <order-information-form v-if="!hiddeSomeBlocksByOrderType" />
    <fulfillment-form v-if="!hiddeSomeBlocksByOrderType" />
    <authorized-dispatch-contacts v-if="!hiddeSomeBlocksByOrderType" />
    <rental-items :order-items="orderItem.order_items" />
    <totals-form v-if="!hiddeSomeBlocksByOrderType" />
    <dispatch-bottom />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import orderInformationForm
from '@/views/main/warehouse/view/dispatch/components/order-information/OrderInformationForm.vue'
import FulfillmentForm from '@/views/main/warehouse/view/dispatch/components/fulfillment/FulfillmentForm.vue'
import AuthorizedDispatchContacts
from '@/views/main/warehouse/view/dispatch/components/authorized-dispatch-contacts/AuthorizedDispatchContacts.vue'
import RentalItems from '@/views/main/warehouse/view/dispatch/components/rental-items/RentalItems.vue'
import TotalsForm from '@/views/main/warehouse/view/dispatch/components/totals/TotalsForm.vue'
import warehouseConfig from '@/views/main/warehouse/config'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import TransferDispatch from '@/views/main/warehouse/view/pick/transfer/components/TransferDispatch.vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import { BCol, BRow } from 'bootstrap-vue'
import RentalSalesReturn from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesReturn.vue'
import RentalSalesDispatch from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesDispatch.vue'
import TransferReturn from '@/views/main/warehouse/view/pick/transfer/components/TransferReturn.vue'
import config from '../dispatchConfig'
import DispatchBottom from './dispatch-bottom/DispatchBottom.vue'

export default {
  components: {
    TransferReturn,
    RentalSalesDispatch,
    RentalSalesReturn,
    BCol,
    BRow,
    ServiceOrderReturn,
    TransferDispatch,
    ServiceOrderDispatch,
    OrderExceptionStatus,
    TotalsForm,
    RentalItems,
    AuthorizedDispatchContacts,
    FulfillmentForm,
    orderInformationForm,
    QuoteStatusBar,
    DispatchBottom,
  },
  data() {
    return {
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    quoteStatusItem() {
      return this.orderItem && {
        state: this.orderItem.fulfillment_state,
        status: this.orderItem.fulfillment_status,
      }
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.orderItem.states)
    },
    hiddeSomeBlocksByOrderType() {
      const paramOrderType = this.orderType.toLowerCase()
      return paramOrderType === this.ORDER_TYPES_KV.SM.toLowerCase() || paramOrderType === this.ORDER_TYPES_KV.TO.toLowerCase()
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const { quoteStatusItemsMerge } = warehouseConfig()
    const { ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME,
      quoteStatusItemsMerge,
      ORDER_TYPES_KV,
    }
  },

}
</script>
