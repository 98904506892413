import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'warehouse'
const Actions = crudActions(endpoint)
const getByProducts = () => axios.get(`${endpoint}?sorting=list`)
const setGlobalVariable = ({ commit }, value) => {
  commit('SET_GLOBAL_VARIABLE', value)
}
const startFulfillment = (_, payload) => axios.post(`${payload.urlAction}/${payload.id}/check-fulfillment`)

const getAssetByBarcode = (_, params) => axios.get('/inventories/list', { params })

const reportAsset = (ctx, data) => axios.post('/orders/missing-report', data)

const receiveAsset = (ctx, data) => axios.post('orders/receive-item', data)

export default {
  ...Actions,
  getByProducts,
  setGlobalVariable,
  reportAsset,
  startFulfillment,
  getAssetByBarcode,
  receiveAsset,
}
