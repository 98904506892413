<template>

  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(ORDER_INFORMATION_TITLE) }}
    </h3>
    <b-row class="mt-2">
      <b-col cols="6">
        <billing />
      </b-col>
      <b-col cols="6">
        <dispatch />
      </b-col>
    </b-row>
    <insurance-information />
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import Billing from '@/views/main/warehouse/view/dispatch/components/order-information/Billing.vue'
import Dispatch
from '@/views/main/warehouse/view/dispatch/components/order-information/Dispatch.vue'
import InsuranceInformation
from '@/views/main/warehouse/view/dispatch/components/order-information/InsuranceInformation.vue'

export default {
  components: {
    InsuranceInformation,
    Dispatch,
    Billing,
    BRow,
    BCol,
  },

  setup() {
    const { ORDER_INFORMATION_TITLE } = config()

    return {
      ORDER_INFORMATION_TITLE,
    }
  },
}
</script>
