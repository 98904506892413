var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.entries(_vm.assetGroupsByCategory)),function(ref){
var key = ref[0];
var values = ref[1];
return _c('div',{key:key},[_c('h2',{staticClass:"font-weight-bolder font-medium-3"},[_vm._v(" "+_vm._s(key)+" ")]),_c('l-table-list-collector',{ref:(key + "_lTableRef"),refInFor:true,staticClass:"tableAsOrderItems",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.packItemTableColumns,"fetched-data":values,"has-footer":false,"show-empty-list-title":"","row-active":{
        filedKey: 'received_at',
        classes: 'highlightRow'
      }},scopedSlots:_vm._u([{key:"cell(view)",fn:function(ref){
      var item = ref.data.item;
return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LStockEyeIcon","size":"32"},on:{"click":function($event){return _vm.goToPage(item,$event)}}})]}},{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item,"with-label":false,"styles":{ color: '#00B139', width: '20px', height: '20px' }}})]}},{key:"cell(id)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.getValueFromGivenObjectByKey(item,'id')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.type_id === 3),expression:"item.type_id === 3"}],staticClass:"icon-wrapper"},[_c('feather-icon',{attrs:{"icon":"LBoxIcon","size":"18"}})],1)])]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item,'product.name'))+" ")]}},{key:"cell(warning_button)",fn:function(ref){
      var item = ref.data.item;
return [(_vm.selectedReceiving)?_c('p',{staticClass:"d-flex justify-content-between align-items-center action-wrapper",staticStyle:{"margin-left":"auto !important","margin-bottom":"0"}},[_c('button-dashed',{staticClass:"mx-auto",staticStyle:{"background-color":"#D9D9D9 !important"},on:{"click":function($event){return _vm.handleReceivedByItemId(item)}}},[_c('feather-icon',{staticClass:"cursor-pointer unpackIcon",attrs:{"icon":"LUnPuckIcon","size":"16"}})],1),_c('button-dashed',{staticClass:"mx-auto",staticStyle:{"background-color":"#D9D9D9 !important"},on:{"click":function($event){return _vm.showReportModal(item.id)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LWarningGreyIcon","size":"16"}})],1)],1):_vm._e(),_c('report-modal',{attrs:{"order-item":item}})]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span'),_c('span',{staticClass:"font-weight-bolder mr-1"},[_vm._v(" Items: "+_vm._s(_vm.orderItem.length)+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }