<template>
  <div>
    <div
      v-if="isSubRented"
      class="subRentText"
    >
      Sub Rental
    </div>
    <span v-else>
      {{ getValueFromGivenObjectByKey(item, 'asset_id') }}
    </span>
  </div>
</template>

<script >

import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'SubRentalAssets',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubRented() {
      return this.item?.rental_sale_type === 'sub-rent'
    },
  },
  methods: { getValueFromGivenObjectByKey },
  setup() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.subRentText{
  text-align: center;
  width: 100px;
  padding: 3px 8px;
  border-radius: 5px;
  border: 1px solid #F0AD4E;
  background: #FBE6CA;
  color: #A17940
}
</style>
