<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Return') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0 mb-2">
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Date') }}
        </span>
      </b-col>
      <b-col cols="10">
        <feather-icon icon="LCalendarsIcon" />
        <span class="font-weight-bolder">
          {{ getValueFromGivenObjectByKey(orderItem,'event_information_rental_end', '') && format(new Date(orderItem.event_information_rental_end * 1000), 'MMM, d, yyyy, hh:mm aaa') }}
        </span>
      </b-col>
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Method') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ getValueFromGivenObjectByKey(orderItem, 'return_method.name') }}
        </span>
      </b-col>
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Address') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span class="font-weight-bolder">
          {{
            `${getValueFromGivenObjectByKey(orderItem, 'return_address.street')}, ${getValueFromGivenObjectByKey(orderItem, 'return_address.line2')}, ${getValueFromGivenObjectByKey(orderItem, 'return_address.city')}, ${getValueFromGivenObjectByKey(orderItem, 'return_address.state.code')} ${getValueFromGivenObjectByKey(orderItem, 'return_address.zip')}, ${getValueFromGivenObjectByKey(orderItem, 'return_address.country.three_letter')}`
          }}        </span>
      </b-col>
      <!--      <b-col cols="2" />-->
      <!--      <b-col cols="10">-->
      <!--        <span>-->
      <!--          {{ $t('154 Bogart Street Brooklyn NY 11206 - Drive In Loading Area') }}-->
      <!--        </span>-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { format } from 'date-fns'

export default {
  name: 'ReturnFulfillment',
  components: {
    BRow,
    BCol,
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME, getValueFromGivenObjectByKey, format,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
