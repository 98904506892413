import { format, getUnixTime, isSameDay } from 'date-fns'

function getCurrentWeek(selectedDate) {
  // eslint-disable-next-line no-undef
  const curr = structuredClone(selectedDate)
  const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay()))
  const lastDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))

  return {
    currentMonthTitle: `${new Date(firstDayOfWeek).toLocaleDateString('en-us', { month: 'short' })}. ${new Date(firstDayOfWeek).toLocaleDateString('en-us', { day: 'numeric' })} —  ${new Date(lastDayOfWeek).toLocaleDateString('en-us', { day: 'numeric' })} '${new Date(lastDayOfWeek).toLocaleDateString('en-us', { year: 'numeric' })}`,
    firstDayOfWeek,
    lastDayOfWeek,
  }
}

export default {
  LIST(state, data) {
    state.warehouseOrdersList = data
  },
  SET_IS_LOADING(state, status) {
    state.isLoading = status
  },
  SET_WAREHOUSE_PAGE_TITLE(state, data) {
    state.warehousePageTitle = data
  },
  SET_GLOBAL_VARIABLE(state, value) {
    state.globalVariable = value
  },
  SET_WAREHOUSE_DATE_PICKER(state, payload) {
    const selectedDate = new Date(payload.value) // Convert to date object from string, unix, date object.
    const moduleName = 'warehouse-orders'

    this.commit(`${moduleName}/SET_IS_LOADING`, true)

    this.dispatch(`${moduleName}/list`, {
      ...payload.params,
      current_date: format(selectedDate, 'yyyy-MM-dd'),
    }).finally(() => {
      this.commit(`${moduleName}/SET_IS_LOADING`, false)
    })
    // Get current week

    const currentWeek = getCurrentWeek(new Date())
    const selectedWeek = getCurrentWeek(selectedDate)

    state.warehouseDatePicker = {
      isSelectedCurrentWeek: isSameDay(selectedDate, new Date()) || (selectedDate >= currentWeek.firstDayOfWeek && selectedDate <= currentWeek.lastDayOfWeek), // Check if selected date is within the current week
      currentMonthTitle: selectedWeek.currentMonthTitle, // Add current month title
      firstSelectedDayOfWeek: selectedWeek.firstDayOfWeek, // Add first selected day of the week
      lastSelectedDayOfWeek: selectedWeek.lastDayOfWeek, // Add last selected day of the week
      selectDayOfWeek: getUnixTime(selectedDate) * 1000, // Convert to milliseconds
    }
  },
}
