<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Return') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col>
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Date') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ orderItem.scheduled_dispatch && format(new Date(orderItem.scheduled_dispatch),'yyyy-MM-dd HH:mm aaa') }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder ">
              {{ $t('Method') }}
            </span>
            <span class="w-50 ">
              {{ $t('Warehouse Pick up') }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Dispatch Location') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ $t(getValueFromGivenObjectByKey(orderItem,'receiving_warehouse.label')) }} {{ $t('Warehouse') }}
            </span>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import { BCol, BRow } from 'bootstrap-vue'
import { format } from 'date-fns'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'TransferReturn',
  components: { BRow, BCol },
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  methods: { getValueFromGivenObjectByKey, format },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
