export default {
  SET_DATA(state, data) {
    state.dispatchData = data
  },
  SET_ORDER_ITEM(state, data) {
    state.orderItem = data
  },
  SET_LOADING(state, data) {
    state.isLoading = data
  },
  SET_DISPATCH_CONTACT_ID(state, data) {
    state.dispatch_contact_id = data
  },
}
