<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Insurance Information') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="6">
        <b-row>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('Policy No.') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ getValueFromGivenObjectByKey(orderItem, 'Policy') }}
            </span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('For Up To') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ getValueFromGivenObjectByKey(orderItem, 'For') }}
            </span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('Deductible') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ getValueFromGivenObjectByKey(orderItem, 'Deductible') }}
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col
            cols="2"
            class="ml-2"
          >
            <span class="font-weight-bolder">
              {{ $t('From') }}
            </span>
          </b-col>
          <b-col cols="9">
            <span>
              {{ getValueFromGivenObjectByKey(orderItem, 'From') }}
            </span>
          </b-col>
          <b-col
            cols="2"
            class="ml-2"
          >
            <span class="font-weight-bolder">
              {{ $t('To') }}
            </span>
          </b-col>
          <b-col cols="9">
            <span>
              {{ getValueFromGivenObjectByKey(orderItem, 'To') }}
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'InsuranceInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return { MODULE_NAME, getValueFromGivenObjectByKey }
  },
}
</script>

<style scoped lang="scss">

</style>
