<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="6">
        <div>
          <div class="bg-white px-1 rounded-lg">
            <span class="font-weight-bolder font-medium-5 d-block pt-2">
              {{ $t('Dispatch') }}
            </span>
            <b-row class="mt-1 pb-1">
              <b-col md="9">
                <b-col
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Date') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Method') }}
                  </span>
                  <span class="w-50">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Dispatch Location') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div>
          <div class="bg-white px-1 rounded-lg">
            <span class="font-weight-bolder font-medium-5 d-block pt-2">
              {{ $t('Return') }}
            </span>
            <b-row class="mt-1 pb-1">
              <b-col md="9">
                <b-col
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Date') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Method') }}
                  </span>
                  <span class="w-50">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Return Location') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="mt-2">
      <div class="bg-white px-1 rounded-lg">
        <span class="font-weight-bolder font-medium-5 d-block pt-2">
          {{ $t('Order Information') }}
        </span>
        <b-row class="mt-1 pb-1">
          <b-col md="6">
            <b-row>
              <b-col mb="8">
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Customer') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Placed by') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
              </b-col>
              <b-col mb="4" />
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col mb="8">
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Agent') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('PO/Event Ref.') }}
                  </span>
                  <span class="w-50 font-weight-bolder">
                    <b-skeleton
                      height="20px"
                      width="150px"
                      style="border-radius: 5px; margin-bottom: 0"
                    />
                  </span>
                </b-col>
              </b-col>
              <b-col mb="4" />
            </b-row>
          </b-col>
          <b-col
            md="6"
            class="mt-1"
          >
            <span class="w-50 ml-2">
              {{ $t('Warehouse Notes') }}
            </span>
            <b-skeleton
              height="100px"
              width="600px"
              style="border-radius: 5px; margin-bottom: 0"
            />
          </b-col>
          <b-col
            md="6"
            class="mt-1"
          >
            <span class="w-50 ml-2">
              {{ $t('Order Notes') }}
            </span>
            <b-skeleton
              height="100px"
              width="600px"
              style="border-radius: 5px; margin-bottom: 0"
            />
          </b-col>

        </b-row>
      </div>
    </div>
    <div class="mt-2">
      <div class="bg-white px-1 rounded-lg">
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          {{ $t('Order Items') }}
        </h2>
        <span>
          <feather-icon
            icon="LWarningIconBlue"
            class="featherIcon"
            size="30"
          />
          {{ $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          <b-skeleton
            height="20px"
            width="600px"
            style="border-radius: 5px; margin-bottom: 0"
          />
          <b-skeleton
            height="500px"
            width="1354px"
            class="mt-2"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </h2>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BCol, BRow, BSkeleton,
} from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BSkeleton,
    BCol,
    BRow,
  },
}
</script>

<style lang="scss" scoped>

</style>
