<template>

  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(FULFILLMENT_TITLE) }}
    </h3>
    <b-row class="mt-2">
      <b-col cols="6">
        <dispatch />
      </b-col>
      <b-col cols="6">
        <return-fulfillment />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import Dispatch
from '@/views/main/warehouse/view/dispatch/components/fulfillment/Dispatch.vue'
import ReturnFulfillment from '@/views/main/warehouse/view/dispatch/components/fulfillment/Return.vue'

export default {
  name: 'FulfillmentForm',
  components: {
    ReturnFulfillment,
    Dispatch,
    BRow,
    BCol,
  },
  setup() {
    const { FULFILLMENT_TITLE } = config()

    return {
      FULFILLMENT_TITLE,
    }
  },
}
</script>
