<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="backToList"
        >
          {{ $t('Back to List') }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          @click="printContent()"
        >
          <feather-icon icon="LPrintIcon" />
          Print Pack List
        </b-button>
        <b-button
          v-if="!hiddeSomeBlocksByOrderType"
          variant="success"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading || !dispatch_contact_id"
          @click="submit"
        >
          <feather-icon icon="LCreditCardIconFill" />
          Check Out
        </b-button>
      </div>
    </div>

  </portal>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { printContent } from '@/views/main/orders/components/helpers'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'

export default {
  name: 'DispatchBottom',
  components: {
    BButton,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    dispatch_contact_id() {
      return this.$store.state[this.MODULE_NAME].dispatch_contact_id
    },
    hiddeSomeBlocksByOrderType() {
      const paramOrderType = this.orderType.toLowerCase()
      return paramOrderType === this.ORDER_TYPES_KV.SM.toLowerCase() || paramOrderType === this.ORDER_TYPES_KV.TO.toLowerCase()
    },
  },
  methods: {
    backToList() {
      this.$router.push({ name: 'home-warehouse-view' })
    },
    submit() {
      const orderPropsByType = this.orderPropsByType(this.orderType)
      if (!orderPropsByType) return
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/dispatchCheckout`, {
        body: { order_id: this.orderItem.id, total_order_notes: this.orderItem.total_order_notes, customer_id: this.dispatch_contact_id },
        orderEndpoint: orderPropsByType.urlAction,
      }).then(() => {
        this.$router.push({ name: 'dispatch-order-signature', params: { id: this.id, orderType: this.orderType } })
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const { orderPropsByType, ORDER_TYPES_KV } = mainOrderConfig()

    return {
      printContent,
      MODULE_NAME,
      orderPropsByType,
      ORDER_TYPES_KV,
    }
  },
}
</script>
