<template>
  <div>
    <div
      v-for="[key,values] in Object.entries(assetGroupsByCategory)"
      :key="key"
    >
      <h2 class="font-weight-bolder font-medium-3">
        {{ key }}
      </h2>
      <l-table-list-collector
        :ref="`${key}_lTableRef`"
        :is-searchable="false"
        :module-name="MODULE_NAME"
        :table-columns="packItemTableColumns"
        :fetched-data="values"
        class="tableAsOrderItems"
        :has-footer="false"
        show-empty-list-title
        :row-active="{
          filedKey: 'received_at',
          classes: 'highlightRow'
        }"
      >
        <template #cell(view)="{data: {item}}">
          <feather-icon
            icon="LStockEyeIcon"
            class="cursor-pointer"
            size="32"
            @click="goToPage(item,$event)"
          />
        </template>
        <template #cell(state)="{data: {item}}">
          <state-of-asset
            :data="item"
            :with-label="false"
            :styles="{ color: '#00B139', width: '20px', height: '20px' }"
          />
        </template>
        <template #cell(id)="{data: {item}}">

          <div class="d-flex align-items-baseline justify-content-between">

            <span>{{ getValueFromGivenObjectByKey(item,'id') }}</span>
            <div
              v-show="item.type_id === 3"
              class="icon-wrapper"
            >
              <feather-icon
                icon="LBoxIcon"
                size="18"
              />
            </div>
          </div>

        </template>
        <template #cell(name)="{data: {item}}">
          {{ getValueFromGivenObjectByKey(item,'product.name') }}
        </template>
        <template #cell(warning_button)="{data: {item}}">
          <p
            v-if="selectedReceiving"
            style="margin-left: auto !important; margin-bottom: 0"
            class="d-flex justify-content-between align-items-center action-wrapper"
          >
            <button-dashed
              style="background-color: #D9D9D9 !important;"
              class="mx-auto"
              @click="handleReceivedByItemId(item)"
            >
              <feather-icon
                icon="LUnPuckIcon"
                size="16"
                class="cursor-pointer unpackIcon"
              />
            </button-dashed>
            <button-dashed
              style="background-color: #D9D9D9 !important;"
              class="mx-auto"
              @click="showReportModal(item.id)"
            >
              <feather-icon
                icon="LWarningGreyIcon"
                size="16"
                class="cursor-pointer"
              />
            </button-dashed>
          </p>
          <report-modal :order-item="item" />
        </template>
      </l-table-list-collector>
    </div>
    <div class="d-flex justify-content-between">
      <span />
      <span class="font-weight-bolder mr-1">
        Items: {{ orderItem.length }}
      </span>
    </div>
  </div>
</template>
<script>

import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import * as _ from 'lodash'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import ReportModal from '@/views/main/warehouse/view/dispatch/components/rental-items/ReportModal.vue'

export default {
  name: 'AsOrdered',
  components: {
    ReportModal, ButtonDashed, LTableListCollector, StateOfAsset,
  },
  props: {
    orderItem: {
      type: Array,
      default: () => [],
    },
    selectedReceiving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    assetGroupsByCategory() {
      return _.groupBy(this.orderItem || [], item => item.product.categories.map(category => category.name).join(', '))
    },
  },
  methods: {
    showReportModal(item) {
      this.$bvModal.show(`report-warning-modal-${item}`)
    },
    handleReceivedByItemId(item) {
      this.$emit('getValue', item.id)
    },
    goToPage(item, event) {
      event.stopPropagation()
      this.gotoNewPage(`/inventory/inventory-view/${item?.product?.id}/update/${item.asset_id}`, event, '_blank')
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return {
      getValueFromGivenObjectByKey,
      MODULE_NAME,
      packItemTableColumns: [
        {
          key: 'view',
          label: '',
          thStyle: { width: '-5%', padding: '0' },
        },
        {
          key: 'state',
          label: 'State',
          thStyle: { width: '10%' },
        },
        {
          key: 'id',
          label: 'Id',
          thStyle: { width: '25%' },
        },
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: '50%' },
        },
        {
          key: 'asset_id',
          label: 'Asset Id',
          thStyle: { width: '30%' },
        },
        {
          key: 'warning_button',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
    }
  },

}

</script>
<style lang="scss">
.tableAsOrderItems{
  tr{
    td{
      &:nth-child(1){
        padding: 5px;
      }
    }
  }
}

.action-wrapper{
  gap: 8px;

  .unpackIcon{
    path{
      fill: #646464;
    }
  }
}
</style>
