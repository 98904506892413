import mainConfig from '@/views/main/warehouse/main-order/config'

export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const MODULE_NAME = 'dispatch'

  const ORDER_INFORMATION_TITLE = 'Order Information'
  const FULFILLMENT_TITLE = 'Fulfillment'

  const preferredContact = [
    {
      value: 'one',
      title: 'Michael Motorist',
    },
    {
      value: 'two',
      title: 'Michelle Zif',
    },
    {
      value: 'three',
      title: 'Jane Doe',
    },
  ]

  const filterTabsList = [
    {
      title: 'By Container',
      query: 'by-container',
      tabNumber: 2,
      pageTitle: 'List By Container',
    },
    {
      title: 'As ordered',
      query: 'as-ordered',
      tabNumber: 3,
      pageTitle: 'List by as Ordered',
    },
  ]

  const getOrderItem = ctx => {
    const orderPropsByType = mainConfig().orderPropsByType(ctx.$route.params.orderType)
    const params = {
      id: ctx.$route.params.id,
      orderEndpoint: orderPropsByType.urlAction,
    }
    ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, true)
    ctx.$store.dispatch(`${MODULE_NAME}/orderItem`, params).finally(() => {
      ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, false)
    })
  }

  return {
    ACCESS_ABILITY_SETTINGS_ORDERS,
    MODULE_NAME,
    ORDER_INFORMATION_TITLE,
    FULFILLMENT_TITLE,
    preferredContact,
    filterTabsList,
    getOrderItem,
  }
}
