<template>

  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t('Totals') }}
    </h3>
    <b-row class="mt-2">
      <b-col cols="6">
        <component
          :is="transferScheduleFields['total_order_notes'].type"
          v-model="orderItem['total_order_notes']"
          v-bind="getProps('total_order_notes', transferScheduleFields)"
        />
      </b-col>
      <b-col cols="6">
        <div class="form-wrapper">
          <b-list-group class="totals-form-list-group">
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_ITEMS_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(rentalTotalPrice) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(saleAndOtherChargesTotalPrice) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_DISCOUNT_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(totalDiscount) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TAXES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(totalTaxes) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(totalPrice) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <!--            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">-->
            <!--              <b-row>-->
            <!--                <b-col cols="6">-->
            <!--                  {{ TOTALS_TOTAL_AMOUNT_PAID_TITLE }}-->
            <!--                </b-col>-->
            <!--                <b-col-->
            <!--                  cols="6"-->
            <!--                  class="text-right"-->
            <!--                >-->
            <!--                  {{ formatNumberToCurrencyView('905') }}-->
            <!--                </b-col>-->
            <!--              </b-row>-->
            <!--            </b-list-group-item>-->
            <!--            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">-->
            <!--              <b-row>-->
            <!--                <b-col cols="6">-->
            <!--                  {{ TOTALS_TOTAL_AMOUNT_DUE_TITLE }}-->
            <!--                </b-col>-->
            <!--                <b-col-->
            <!--                  cols="6"-->
            <!--                  class="text-right"-->
            <!--                >-->
            <!--                  {{ formatNumberToCurrencyView('908') }}-->
            <!--                </b-col>-->
            <!--              </b-row>-->
            <!--            </b-list-group-item>-->
          </b-list-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BCol, BListGroup, BListGroupItem, BRow,
} from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/components/totals/totalsConfig'
import { getProps, formatNumberToCurrencyView, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'
import * as _ from 'lodash'

export default {
  name: 'TotalsForm',
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    rentalItems() {
      return _.filter(this.orderItem.order_items, { rental_sale_type: 'rent' })
    },
    saleItems() {
      return _.filter(this.orderItem.order_items, { rental_sale_type: 'sale' })
    },
    otherCharges() {
      return this.orderItem.other_charges
    },
    totalTaxes() {
      return this.sumTotalAmountOfGivenArrayByProperty(_.concat(this.orderItem.order_items, this.orderItem.other_charges.map(i => ({ taxes: i.tax }))), 'taxes')
    },
    totalDiscount() {
      return this.sumTotalAmountOfGivenArrayByProperty(_.concat(this.orderItem.order_items, this.orderItem.other_charges), 'discount')
    },
    saleAndOtherChargesTotalPrice() {
      return this.sumTotalAmountOfGivenArrayByProperty(_.concat(this.saleItems, this.orderItem.other_charges.map(i => ({ ...i, amount: i.unit }))).map(t => t.amount * t.price), 'subtotal')
    },
    rentalTotalPrice() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.rentalItems.map(i => i.amount * i.price), 'subtotal')
    },
    totalPrice() {
      return this.rentalTotalPrice + this.saleAndOtherChargesTotalPrice + this.totalTaxes
    },
  },
  setup() {
    const {
      transferScheduleFields,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_TOTAL_AMOUNT_PAID_TITLE,
      TOTALS_TOTAL_AMOUNT_DUE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    } = config()

    return {
      transferScheduleFields,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_TOTAL_AMOUNT_PAID_TITLE,
      TOTALS_TOTAL_AMOUNT_DUE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
      formatNumberToCurrencyView,
      sumTotalAmountOfGivenArrayByProperty,
      getProps,
      MODULE_NAME: 'dispatch',
    }
  },
}
</script>
