import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const MODULE_NAME = 'pick-rental-sales-order'

  const PICKED_ACTION_STATE = 'Pick'
  const PACKED_ACTION_STATE = 'Pack'

  const tableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '5%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'location',
      label: 'Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '10%' },
    },
    {
      key: 'status',
      label: 'Picked',
      thStyle: { width: '12%' },
    },
    {
      key: 'inspected_outbound',
      label: 'Inspected',
      thStyle: { width: '25%' },
    },
  ]
  const quoteStatusBar = [
    {
      icon: 'LInOutIcon',
      title: 'Schedule',
      iconSize: '22',
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '22',
    },
    {
      icon: 'LWarehouseIcon',
      title: 'Receive',
      iconSize: '22',
    },
  ]
  const RentalSalesPickFields = {
    warehouseNotes: {
      type: TEXTAREA_INPUT,
      label: 'Warehouse Notes',
      placeholder: 'Write notes here...',
      options: {
        cols: '12',
      },
    },
    orderNotes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes',
      placeholder: 'User 1 @ Sep 10, 2022: Some order notes.',
      options: {
        cols: '12',
      },
    },
  }

  return {
    tableColumns,
    quoteStatusBar,
    PICKED_ACTION_STATE,
    PACKED_ACTION_STATE,
    MODULE_NAME,
    RentalSalesPickFields,
    ACCESS_ABILITY_SETTINGS_ORDERS,
  }
}
